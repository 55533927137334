import { template as template_01fb34607a674e589c7fbc3fed2b8e01 } from "@ember/template-compiler";
const FKLabel = template_01fb34607a674e589c7fbc3fed2b8e01(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
